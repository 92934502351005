@import url("https://fonts.googleapis.com/css?family=Fira+Sans:300,400,500,600,700,800,900&display=swap");

/*Modules*/

* {
    padding: 0;
    margin: 0;
}


/* colors variable start here */

.clr-green {
    color: #33A02C;
}

.clr-orange {
    color: #FF9900;
}

.clr-red {
    color: #E61800;
}


/* buttons code */

.custom-outline-btn {
    min-width: 194px;
}

.custom-outline-btn .mdc-button__label {
    color: #142942;
}

.custom-outline-btn .mdc-button__ripple {
    border-color: #0A50A1;
}

.mdc-button.green-btn {
    background-color: #33A02C;
    border-radius: 0;
}

.custom-dashed-button .btn-large {
    border: 1px dashed #801782;
    color: #801782;
    opacity: 0.8;
    font-size: 16px;
    text-transform: capitalize;
    height: 56px;
}


/* textfield styles */

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: #DADADA;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: #557190;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
    border-color: #0a50a1;
}

.mdc-text-field--outlined {
    width: 100%;
    position: relative;
}

.mdc-text-field--outlined .material-icons {
    color: #0A50A1;
    position: absolute;
    right: 15px;
    top: 15px;
}


/* common style */

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
    display: block;
}

label {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #142942;
}

.button-style-visit {
    width: 11rem;
}


/* grid custom cols*/

.next-prev-btns button {
    min-width: 107px;
    margin: 10px 10px !important;
}

@media (min-width: 768px) {
    .custom-row {
        margin-left: -7px;
        margin-right: -7px;
    }

    .custom-row .custom-col {
        padding: 0 7px;
    }
}

body {
    font-family: 'Roboto', sans-serif;
}


/* fonts */


/* heading code start here */

.common-heaidng48 {
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    color: #142942;
}

@media (max-width: 767px) {
    .common-heaidng48 {
        font-size: 30px;
    }
}

.small-title {
    font-size: 20px;
    line-height: 25px;
    color: #557191;
    font-weight: 400;
}

.title18 {
    font-size: 18px;
    font-weight: 600;
    color: #142942;
}

.common-heading36 {
    color: #142942;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 45px;
    letter-spacing: 0.15px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Source Sans Pro", sans-serif;
}

.forget-pwd-link {
    font-size: 18px;
    line-height: 23px;
    color: #0A50A1;
    font-family: "Source Sans Pro", sans-serif;
    text-decoration: none;
}

body {
    overflow: visible !important;
    padding-right: 0 !important;
}

.top-bar {
    padding: 35px 0px;
}

.top-bar .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.add-profile-pic .caption {
    font-size: 16px;
    color: #142942;
    line-height: 20px;
    margin: 15px 0px;
    font-weight: 400;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
}

.mtb-70 {
    margin: 70px 0px;
}

.view-all-link {
    color: #33A02C;
    font-weight: 600;
    font-size: 18px;
    font-family: "Source Sans Pro", sans-serif;
}


/* accordion css start here */

.accordion-container .accordion-title {
    position: relative;
    margin: 0;
    padding: 0.625em 0em 0.625em 1.5em;
    cursor: pointer;
    color: #142942;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
}

.accordion-container .accordion-title::after {
    content: "";
    position: absolute;
    top: 25px;
    left: 9px;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-top-color: #800080;
}

.accordion-container .accordion-title.open::after {
    content: "";
    position: absolute;
    top: 15px;
    border: 8px solid transparent;
    border-bottom-color: #800080;
}

.accordion-container .accordion-title .view-all-link {
    float: right;
}


/* accordion css end here */


/* notice listing css start here */

.notice-list {
    overflow: hidden;
}

.notice-list li {
    display: block;
    list-style: none;
    clear: both;
}

.notice-list .notice-media {
    float: left;
    width: 100px;
}

.notice-list .notice-media img {
    width: 100%;
    height: 56px;
    -o-object-fit: cover;
    object-fit: cover;
}

.notice-list .notice-info {
    display: inline-block;
    width: calc(100% - 120px);
    margin-left: 20px;
    float: left;
    position: relative;
    padding-top: 10px;
    border-bottom: 1px solid rgba(10, 80, 161, 0.1);
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.notice-list .notice-info .line-icon {
    position: absolute;
    right: 0;
}

.notice-list .notice-info p {
    color: #142942;
    font-size: 16px;
}

.notice-list .notice-info span {
    color: #557190;
    font-size: 14px;
}

.notice-list .notice-info span.material-icons {
    font-size: 24px;
}

.notice-list a {
    text-decoration: none;
}


/* notice listing css end here */

.common-listing-block {
    background: #F3F6FA;
    -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    width: 444px;
    position: absolute;
    z-index: 9;
    padding: 25px;
}

.common-listing-block .mdc-list-item__secondary-text::before {
    height: 0;
    display: none;
}

.common-listing-block ul li {
    margin: 10px 0px;
}

.common-listing-block ul li span.mdc-list-item__text {
    border-bottom: 1px solid rgba(10, 80, 161, 0.1);
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 2px;
    position: relative;
}

.common-listing-block ul li span.mdc-list-item__text span.material-icons.arrow-forward {
    position: absolute;
    right: 0;
    top: 0px;
    color: #0A50A1;
}

.common-listing-block ul li span.mdc-list-item__primary-text {
    color: #142942;
}

.common-listing-block ul li span.material-icons {
    margin: 6px 25px 0 0px;
}

.common-listing-block ul li span.material-icons.arrow-forward {
    margin: 8px 25px 0 0px;
}

.common-listing-block ul li:last-child span.mdc-list-item__text {
    border-bottom: 0;
}

.common-listing-block a {
    text-decoration: none;
}

.dropdown-list,
.find-by {
    font-weight: 600;
    font-size: 18px;
    font-family: "Source Sans Pro", sans-serif;
    color: #0A50A1;
    cursor: pointer;
}

.dropdown-list .material-icons,
.find-by .material-icons {
    position: relative;
    top: 5px;
}

.find-by {
    margin-right: 15px;
}

.detail-media-block {
    position: relative;
}

.detail-media-block img {
    width: 100%;
    height: 444px;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 4px;
    margin-bottom: 45px;
}

.detail-media-block .material-icons {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #FFE800;
    font-size: 138px;
    cursor: pointer;
}

.meeting-notes {
    margin: 25px 0px;
}

.meeting-notes .title18 {
    margin-bottom: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.meeting-notes .title18 span.material-icons {
    color: #0A50A1;
    margin-right: 9px;
}

.meeting-notes p {
    font-size: 18px;
    color: #557191;
    font-family: "Source Sans Pro", sans-serif;
}

.card-block {
    border: 1px solid rgba(10, 80, 161, 0.1);
    border-radius: 4px;
    background: #fff;
}

.card-block-without-border {
    border: 1px solid rgb(255, 255, 255);
    border-radius: 4px;
    background: #fff;
}

.card-block .card-header {
    padding: 15px;
    position: relative;
}

.card-block .card-header .small-title {
    color: #142942;
    font-weight: 500;
    font-family: Roboto;
}

.card-block .card-header p {
    font-size: 14px;
}

.card-block .card-header span.material-icons {
    position: absolute;
    right: 12px;
    top: 21px;
}

.card-block .card-media img {
    height: 197px;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
}

.card-block .bottom-descp {
    padding: 15px;
}

.card-block .bottom-descp p {
    color: #557191;
    font-size: 14px;
}

.folders-listing {
    position: relative;
}

.folders-listing ul li {
    color: #557190;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding-left: 0;
    list-style: none;
}

.folders-listing ul li ul {
    padding-left: 10px;
}

.folders-listing ul li ul li {
    font-size: 14px;
}

.folders-listing ul li .material-icons {
    position: relative;
    top: 6px;
    margin-right: 15px;
}

.folders-listing ul li text {
    display: inline-block;
    border-bottom: 1px solid rgba(10, 80, 161, 0.1);
    width: 85%;
    padding-bottom: 5px;
}

.folders-listing span.material-icons.arrow-upward {
    position: absolute;
    right: 0;
    top: 0;
}

.big-folders-list li {
    width: 25%;
    float: left;
    list-style: none;
    padding: 0 15px;
    margin-bottom: 25px;
    position: relative;
    height: 192px;
}

.big-folders-list li img {
    width: 100%;
    max-height: 150px;
}

.big-folders-list li p {
    margin-top: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    word-break: break-all;
}

.big-folders-list li img.small-thumb {
    width: auto;
    height: auto;
    position: absolute;
    right: 10px;
    bottom: 12px;
}

.big-folders-list li figure {
    position: relative;
}

.user-block .user-media-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.user-block .user-media-row .media {
    position: relative;
    width: 98px;
}

.user-block .user-media-row .media .small-thumb {
    position: absolute;
    bottom: 0;
    right: -7px;
}

.user-block .user-media-row .media-info {
    padding-left: 25px;
}

.user-block .user-media-row .media-info h2 {
    color: #142942;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 0;
}

.user-block ul li .links-notify {
    position: relative;
}

.user-block ul li .links-notify .notify-circle {
    position: absolute;
    left: 13px;
    top: 6px;
}

.notify-circle {
    background: #33A02C;
    color: #fff;
    font-size: 10px;
    font-weight: 500;
    width: 15px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: 15px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
}

.logout-link {
    font-size: 18px;
    font-family: "Source Sans Pro", sans-serif;
    letter-spacing: 0.18px;
    padding: 0 10px;
    border: 0;
    background: transparent;
    color: #142942;
    font-weight: 400;
}

.user-pages-accordion-wrap .accordion-container .accordion-title {
    padding-left: 0;
    border-bottom: 1px solid rgba(10, 80, 161, 0.1);
    padding-bottom: 7px;
    margin-bottom: 10px;
    color: #800080;
    font-size: 20px;
}

.user-pages-accordion-wrap .accordion-container .accordion-title::after {
    left: auto;
    right: 0;
    /* background: url(../images/arrow-right.svg); */
    background-repeat: no-repeat;
    border: none;
    height: 16px;
    width: 15px;
}

.user-pages-accordion-wrap .accordion-container .accordion-title.open::after {
    /* background: url(../images/arrow-down.svg); */
    background-repeat: no-repeat;
    top: 27px;
}

.user-pages-accordion-wrap .mdc-text-field--outlined {
    margin: 15px 0px;
}

.user-pages-accordion-wrap .operational-days-listing li {
    width: 100%;
}

.user-pages-accordion-wrap .operational-days-block label {
    color: #142942;
    margin-bottom: 10px;
}

.user-pages-accordion-wrap .user-caption-wrap {
    margin-bottom: 30px;
}

.edit-profile {
    font-size: 18px;
    font-weight: 600;
    color: #33A02C;
    text-decoration: none;
}

.user-profile-block .email {
    color: #557190;
    font-size: 36px;
    font-weight: 400;
    margin-bottom: 5px;
}

.user-caption-wrap span {
    display: block;
    color: #557191;
    font-size: 20px;
    font-family: "Source Sans Pro", sans-serif;
    margin-top: 3px;
}

.user-caption-wrap label {
    color: #142942;
}

.view-profile-page .notice-list .notice-media img {
    height: 100px;
}

.completed-tag,
.inprogess-tag,
.pending-tag {
    border-radius: 1px;
    color: #fff;
    font-size: 10px;
    text-transform: uppercase;
    padding: 3px 5px;
    display: inline-block;
    margin-bottom: 5px;
}

.completed-tag {
    background: #33A02C;
}

.inprogess-tag {
    background: #0A50A1;
}

.pending-tag {
    background: #FF9900;
}

.event-user-list li {
    display: inline-block;
    margin-right: 5px;
}

.event-user-list li img {
    height: 44px;
    width: 44px;
    border-radius: 50%;
}

.thank-you-popup h2 {
    color: #142942;
    font-size: 24px;
    margin: 25px 0px;
}

.thank-you-popup p {
    color: #557191;
    font-size: 18px;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
    background: #557190;
    border-color: #557190;
}

.dropdown {
    display: none;
    position: absolute;
    margin-top: 5px;
    padding: 5px 5px 0 0;
    right: 0;
    top: 28px;
    z-index: 99;
    background: #FFFFFF;
    -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 20px;
}

.dropdown-3 {
    display: none;
    position: absolute;
    margin-top: 5px;
    padding: 5px 5px 0 0;
    right: 0;
    top: 28px;
    z-index: 99;
    background: #FFFFFF;
    -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 20px;
}

.dropdown li {
    list-style: none;
    color: #142942;
    font-size: 16px;
    margin: 10px 0px;
}

.dropdown li a {
    color: #142942;
    font-size: 16px;
    text-decoration: none;
}

.calender-popup {
    -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1), 0px 24px 48px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1), 0px 24px 48px rgba(0, 0, 0, 0.2);
    padding: 25px 45px;
    position: relative;
}

.calender-popup h2 {
    color: #142942;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;
}

.calender-popup .common-heading36 {
    margin-bottom: 25px;
}

.calender-popup .green-btn {
    width: 100%;
    margin-top: 30px;
}

span.material-icons.close-icon {
    position: absolute;
    right: 33px;
    top: 27px;
    color: #557191;
    cursor: pointer;
}

#tabs-nav li {
    list-style: none;
    display: inline-block;
    width: 49%;
    text-align: center;
    border-bottom: 2px solid transparent;
    padding-bottom: 10px;
}

#tabs-nav li.active {
    border-color: #104786;
}

#tabs-nav li.active a {
    color: #0A50A1;
}

#tabs-nav li a {
    font-size: 14px;
    font-weight: 500;
    color: #142942;
    text-transform: uppercase;
    text-decoration: none;
}


/* textfield style */

.outlined-textfield-wrap {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.material-textfield {
    position: relative;
    width: 100%;
}

.material-textfield label {
    position: absolute;
    font-size: 1rem;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background-color: white;
    color: #557190;
    padding: 0 0.3rem;
    margin: 0 0.5rem;
    -webkit-transition: .1s ease-out;
    -o-transition: .1s ease-out;
    transition: .1s ease-out;
    -webkit-transform-origin: left top;
    -ms-transform-origin: left top;
    transform-origin: left top;
    pointer-events: none;
    font-weight: 400;
}

.material-textfield input,
.material-textfield textarea {
    font-size: 1rem;
    outline: none;
    border: 1px solid #DADADA;
    border-radius: 5px;
    padding: 1rem 0.7rem;
    color: gray;
    -webkit-transition: 0.1s ease-out;
    -o-transition: 0.1s ease-out;
    transition: 0.1s ease-out;
    width: 100%;
}

.material-textfield input:focus,
.material-textfield textarea:focus {
    border-color: #0a50a1;
}

.material-textfield input:focus+label,
.material-textfield textarea:focus+label {
    color: #0a50a1;
    top: 0;
    -webkit-transform: translateY(-50%) scale(0.9);
    -ms-transform: translateY(-50%) scale(0.9);
    transform: translateY(-50%) scale(0.9);
}

.material-textfield textarea.active {
    border-color: #0a50a1;
}

.material-textfield textarea.active+label {
    color: #0a50a1;
    top: 0;
    -webkit-transform: translateY(-50%) scale(0.9);
    -ms-transform: translateY(-50%) scale(0.9);
    transform: translateY(-50%) scale(0.9);
}


/*.material-textfield input:not(:placeholder-shown) + label {
  top: 0;
  transform: translateY(-50%) scale(.9);
}*/

.mt-40 {
    margin-top: 40px;
}

.btn-right {
    margin-top: 25px;
    text-align: right;
    /* margin-left: 210px; */
}


.br50 {
    border-radius: 40px !important;
}

.signature-field {
    margin-top: 30px;
}

.mt-10 {
    margin-top: 10px;
}

.big-folders-list+.btn-right {
    float: left;
    text-align: right;
    width: 100%;
}

.add-folders-popup {
    max-width: 414px;
    background: #FFFFFF;
    border-radius: 4px 4px 0px 0px;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    padding: 25px;
    text-align: center;
    margin: 0 auto;
    width: 414px;
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.add-folders-popup .mdc-button {
    width: 100%;
    margin: 7px 0px;
}

.add-folders-popup .mdc-button .mdc-button__label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.add-folders-popup .mdc-button span.material-icons {
    margin-right: 10px;
}

.add-folders-popup .mdc-button.add-folder {
    border-color: #0A50A1;
    color: #0A50A1;
}

.add-folders-popup .mdc-button.upload-btn {
    border-color: #33A02C;
    color: #33A02C;
}

.add-folders-popup h3 {
    font-size: 22px;
    line-height: 28px;
    color: #142942;
    font-weight: 400;
    margin-bottom: 20px;
}

.add-folders-popup.new-folder {
    text-align: left;
    z-index: 123;
}

.add-folders-popup.new-folder h3 {
    text-align: center;
}

.add-folders-popup.new-folder button {
    width: auto;
}

.add-folders-popup.new-folder label {
    font-size: 16px;
}

.map-media img {
    width: 100%;
}

.textfield-with-small-desciption {
    position: relative;
}

.textfield-with-small-desciption small {
    position: absolute;
    left: 0;
    bottom: -4px;
    color: rgba(0, 0, 0, 0.6);
}

.mt-25 {
    margin-top: 25px;
}

.select-field {
    position: relative;
    cursor: default;
}

.select-field .dropdown {
    left: 0;
    text-align: left;
    top: 66px;
}

.mdc-text-field--outlined .mdc-text-field__input:focus+.mdc-notched-outline .mdc-floating-label,
.mdc-text-field--outlined .mdc-text-field__input.active+.mdc-notched-outline .mdc-floating-label {
    top: 0px;
    background: #fff;
    color: #0A50A1;
    font-size: 12px;
}

.mdc-text-field--outlined .mdc-notched-outline .mdc-floating-label {
    -webkit-transition: 0.1s ease-in-out;
    -o-transition: 0.1s ease-in-out;
    transition: 0.1s ease-in-out;
}

.mdc-text-field--outlined .mdc-text-field__input {
    color: #0A50A1 !important;
}

.mdc-text-field--outlined .mdc-text-field__input.active+.mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined .mdc-text-field__input.active+.mdc-notched-outline .mdc-notched-outline__trailing,
.mdc-text-field--outlined .mdc-text-field__input.active+.mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined .mdc-text-field__input:focus+.mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined .mdc-text-field__input:focus+.mdc-notched-outline .mdc-notched-outline__trailing,
.mdc-text-field--outlined .mdc-text-field__input:focus+.mdc-notched-outline .mdc-notched-outline__notch {
    border-color: #0A50A1;
}


/*.upload-doc-block {
    border-bottom: 1px solid #DADADA;
}
*/


/****** File upload css Code ******/

.file-upload .file-select .file-select-button {
    padding: 0 10px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: 40px;
    line-height: 40px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.file-upload .file-select {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: #34495e;
    cursor: pointer;
    height: 40px;
    line-height: 40px;
    text-align: left;
    background: #FFFFFF;
    overflow: hidden;
    position: relative;
}

.file-upload .file-select .file-select-name {
    line-height: 40px;
    display: inline-block;
    padding: 0 10px;
}

.file-upload .file-select input[type=file] {
    z-index: 100;
    cursor: pointer;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    filter: alpha(opacity=0);
}

.task-listing.scroll-element {
    max-height: 310px;
    overflow-x: hidden;
    overflow-y: scroll;
}

.occupants-fields-wrap .custom-row {
    position: relative;
}

.occupants-fields-wrap .custom-row span.material-icons.close {
    position: absolute;
    bottom: 32px;
    right: -40px;
    cursor: pointer;
    color: #557191;
}

.header-bar .right-block .notification-block .common-listing-block {
    max-height: 425px;
    overflow-x: hidden;
    overflow-y: auto;
}

.detail-media-block iframe {
    width: 100%;
    height: 444px;
    border: none;
    border-radius: 8px;
}

@media (max-width: 767px) {
    body .detail-media-block iframe {
        height: 300px;
        margin-top: 30px;
    }
}

button.mdc-button {
    border-radius: 0;
}

form .MuiFormControl-root {
    width: 100%;
    margin: 15px 0 0;
}

.dashboard-wrap .notice_list .card-block {
    margin-bottom: 30px;
}

.count-field {
    background: #0a50a1;
    color: #fff;
    font-size: 13px;
    padding: 6px;
    border-radius: 50%;
    width: 32px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    text-align: center;
    margin: 0 0 0 -15px;
    z-index: 2;
    position: relative;
    height: 32px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.add-profile-pic .profile-pic-inner {
    width: 121px;
    height: 120px;
    margin: 0 auto 15px;
    position: relative;
}

.add-profile-pic .profile-pic-inner img {
    height: 121px;
    width: 121px;
    border-radius: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.add-profile-pic .profile-pic-inner input {
    left: 0;
    width: 100%;
    height: 100%;
}

.next-prev-btns .mdc-button {
    margin: 0 15px 0 0;
}

.full-width-calender .Calendar {
    width: 100%;
    margin-top: 1.2rem;
}

.full-width-calender .Calendar .Calendar__day {
    border-radius: 100% !important;
    font-size: 17px;
    min-height: 32px;
    padding: 0 !important;
    width: 34px;
    margin: 6px 30px;
}

.full-width-calender .Calendar .Calendar__section.-hiddenNext {
    transform: translateX(91%);
}

.full-width-calender .Calendar .Calendar__section.-hiddenPrevious {
    transform: translateX(-91%);
}

.full-width-calender .Calendar .Calendar__day.greenDay,
.full-width-calender .Calendar .Calendar__day.purpleDay {
    color: #fff;
}

.full-width-calender .Calendar .Calendar__section {
    padding: .5em 3.6em 0;
}

.full-width-calender .Calendar .Calendar__day.greenDay.-today {
    color: #fff;
}

.full-width-calender .Calendar .Calendar__day.greenDay.-today:after {
    opacity: 0;
}

.full-width-calender .Calendar .Calendar__weekDay {
    font-size: 20px;
}

.calender-top-button button.mdc-button {
    width: 114px;
    margin: 0 3px;
}

.full-width-calender .Calendar .Calendar__header {
    padding-top: 10px;
    padding-bottom: 29px;
}

.full-width-calender .Calendar .Calendar__sectionWrapper {
    min-height: 305px;
}

.Calendar {
    background: #F3F6FA;
    -webkit-box-shadow: none;
    box-shadow: none;
}

body .Calendar .Calendar__day {
    color: #142942;
}

.Calendar .Calendar__weekDay {
    color: #142942;
    opacity: 0.5;
}

.signature-block {
    border: 1px solid #dadada;
    border-radius: 5px;
    width: 100%;
    margin: 30px 0px;
    position: relative;
}

.signature-block label {
    position: absolute;
    left: 0;
    top: -25px;
    font-size: 15px;
}

.status-not-complete span,
.status-complete span,
.status-due span,
.status-overdue span {
    color: #fff;
    padding: 6px 10px 4px;
    display: block;
    text-align: center;
    text-transform: uppercase;
}

.status-not-complete span {
    background: #0950a2;
}

.status-complete span {
    background: #33a02b;
}

.status-due span {
    color: #000 !important;
    background: #fee800;
}

.status-overdue span {
    background: #e61800;
}

.action-btn-style {
    padding: 0 10px;
    color: #fff;
    border: none;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    text-decoration: none;
    font-size: 14px;
    width: 60px;
    text-align: center;
    cursor: pointer;
    height: 25px;
    vertical-align: top;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    line-height: 14px;
}

a.action-btn-style {
    padding-top: 3px;
}

.bg-green {
    background: #33a02c;
}

.bg-red {
    background: #e61800;
}

.mt-5 {
    margin-top: 30px;
}

.bg-yellow {
    color: #333;
    background-color: yellow;
    margin-left: 3px;
}

ul.big-folders-list li .dropdown-2 {
    position: absolute;
    background: #fff;
    -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.75);
    padding: 10px;
    z-index: 9;
    width: 152px;
}

ul.big-folders-list li .dropdown-2 li {
    display: block;
    width: 100%;
    margin: 3px 0 0;
    padding: 0;
    cursor: pointer;
    height: auto;
}

.big-folders-list.folder-open-list li img {
    height: 160px;
    -o-object-fit: cover;
    object-fit: cover;
}

.folders-listing ul li ul li.active text {
    color: #33a02c;
}

.folders-listing ul li ul li.active .material-icons {
    color: #33a02c;
}

.MuiDialog-paperWidthSm {
    max-width: 800px;
    width: 800px;
}

.MuiDialog-paperWidthSm form {
    width: 100%;
}

table.MuiTable-root .user-media img {
    max-height: 50px;
    width: 50px;
}

.user-block .user-media img {
    width: 48px;
}

.common-header-row.risk--assignment {
    position: relative;
}

.common-header-row.risk--assignment .risk-assi-title h4 {
    position: absolute;
    left: 0;
}

.common-header-row.risk--assignment .risk-assi-btns {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

.common-header-row.risk--assignment .risk-assi-btns button {
    margin-left: 15px;
}

.common-listing-block ul li.mdc-list-item.logout span.material-icons {
    color: #551a8b;
    margin-right: 23px;
    margin-left: 2px;
}

.MuiDialogContent-root .select-field ul.dropdown-2 {
    position: absolute;
    background: #fff;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.75);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.75);
    padding: 10px;
    z-index: 9;
    width: 100%;
}

.MuiDialogContent-root .select-field ul.dropdown-2 li {
    list-style-type: none;
    display: block;
    width: 100%;
    margin: 3px 0 10px;
    padding: 0;
    cursor: pointer;
    height: auto;
}

.calendar-wrap .react-calendar {
    width: 100%;
}

.calendar-wrap-small .react-calendar {
    width: 80%;
}

.custom-title-link a {
    font-size: 16px;
    color: #000;
    font-weight: 500;
    text-decoration: none;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.custom-title-link a:hover {
    color: #07bc0c;
}

form.form-meeting-notes .meeting-notes {
    clear: both;
}

form.form-meeting-notes .meeting-notes:nth-child(2) {
    float: left;
    width: auto;
    margin-right: 10px;
}

form.form-meeting-notes .meeting-notes button {
    display: block;
    margin-bottom: 10px;
}

.top-bar.header-bar .right-block .user-block figure.user-media {
    margin-bottom: 0;
}

@media (max-width: 767px) {
    .common-header-row.common-header-with-large-text {
        display: block;
        text-align: center;
    }

    .common-header-row.risk--assignment .risk-assi-title {
        margin-bottom: 15px;
    }

    .risk-assi-btns {
        display: block;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
    }
}

@media (max-width: 1199px) and (min-width: 992px) {
    form.sign-up-form .mdc-text-field--outlined .mdc-notched-outline .mdc-floating-label {
        font-size: 14px;
    }

    .full-width-calender .Calendar .Calendar__day {
        width: 30px;
        margin: 6px 22px;
    }

    .header-bar .right-block ul.menu-list li {
        margin: 0 12px;
    }

    .header-bar .right-block .menu-list li a {
        font-size: 13px;
    }
}

@media (max-width: 991px) and (min-width: 768px) {
    .full-width-calender .Calendar .Calendar__day {
        margin: 6px 10px;
    }
}

@media (max-width: 991px) {
    .notice-listing-block .task-list-with-media table tbody tr td.MuiTableCell-root a.educatorProfile {
        display: inline-block;
        text-align: center;
    }
}

@media (max-width: 767px) {
    .login-form .forget-pwd-link {
        margin-top: 10px;
    }

    .common-heaidng48 {
        line-height: 32px;
    }

    .Calendar__weekRow {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

    .full-width-calender .Calendar .Calendar__day {
        width: 30px;
        margin: 6px 10px;
    }

    .dashboard-wrap .content-block-right .notice-listing-block .block-right.row {
        -ms-flex-wrap: unset;
        flex-wrap: unset;
        padding: 0 16px;
        margin-top: 15px;
    }

    .dashboard-wrap .content-block-right .notice-listing-block .common-header-row {
        display: block;
    }

    .big-folders-list li {
        width: 50%;
        height: auto;
    }

    .meeting-notes a {
        word-break: break-word;
    }
}

@media (max-width: 575px) {
    .full-width-calender .Calendar .Calendar__day {
        font-size: 13px;
        min-height: 25px;
        width: 25px;
        margin: 6px 0;
    }

    .user-profile-block .email {
        font-size: 24px;
    }

    .dashboard-wrap .content-block-right .common-header-row .btn-right.upload-btn {
        margin-top: 0;
    }

    .dashboard-wrap .content-block-right .common-header-row {
        margin-bottom: 30px;
    }

    .content-block-right .common-header-row .btn-right.upload-btn .mdc-button input[type="file"] {
        width: 100%;
    }

    .common-header-row h4 .green-btn {
        font-size: 12px;
    }

    .dashboard-wrap .content-block-right .meeting-notes iframe {
        width: 100%;
    }

    .calender-popup.booking-popup {
        width: 100%;
        margin: auto;
        max-width: 380px;
    }
}

@media (max-width: 375px) {
    .top-bar a.main-logo img {
        width: 90px;
        margin-top: 4px;
    }

    .user-bloxes-list li .designaiton {
        font-size: 16px;
        line-height: 20px;
    }

    .user-bloxes-list li p {
        font-size: 12px;
    }

    .common-header-row h4 {
        font-size: 22px;
        line-height: 28px;
    }
}


/*partials*/

.menu-list li {
    display: inline-block;
    margin: 0 15px;
}

.menu-list li a {
    text-transform: uppercase;
    color: #557190;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    letter-spacing: 1.25px;
}

.menu-list li a:hover {
    color: #0A50A1;
}

.menu-list li.active a {
    color: #0A50A1;
}

.menu-list li .material-icons {
    position: relative;
    top: 5px;
    margin-right: 5px;
}

.header-bar {
    -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    padding: 26px 0px 10px;
}

.header-bar .right-block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
}

.notice_list .card-block-without-border .card-header {
    display: flex;
    justify-content: space-between;
    padding: 0;
}

.notice_list .card-block-without-border .card-header .action-block {
    display: inline-flex;
    gap: 5px;
}

.notification-block {
    position: relative;
}

.notification-block span.count-field {
    position: absolute;
    top: -13px;
    right: -15px;
}

@media (max-width: 991px) {
    .header-bar .right-block {
        position: unset;
    }
}

.header-bar .right-block .menu-list,
.header-bar .right-block .notification-block,
.header-bar .right-block .user-block {
    display: inline-block;
}

.header-bar .right-block .common-listing-block {
    right: 0;
    top: 62px;
}

.header-bar .right-block .notification-block .common-listing-block,
.header-bar .right-block .user-block .common-listing-block {
    display: none;
}

.header-bar .right-block .notification-block figure.user-media img,
.header-bar .right-block .user-block figure.user-media img {
    width: 48px;
    -o-object-fit: cover;
    object-fit: cover;
}

@media (min-width: 992px) {
    .header-bar .right-block .menu-list {
        display: block !important;
    }
}

@media (max-width: 991px) {
    .header-bar .right-block .menu-list {
        position: absolute;
        left: 0;
        top: 83px;
        background: #fff;
        z-index: 99;
        width: 100%;
        padding-left: 15px;
        display: none;
    }
}

@media (max-width: 991px) {
    .header-bar .right-block .menu-list li {
        width: 100%;
        margin: 10px 0px;
    }
}

.header-bar .user-block {
    margin-left: 20px;
    position: relative;
    top: -7px;
}

.header-bar .mobile-toggel-icon {
    font-size: 34px;
    margin-left: 15px;
}

@media (min-width: 992px) {
    .header-bar .mobile-toggel-icon {
        display: none;
    }
}

.notification-block .material-icons.notify-icon {
    color: #801782;
    font-size: 29px;
    position: relative;
    top: 3px;
    cursor: pointer;
}

.user-block .user-media img {
    max-width: 48px;
    border-radius: 50%;
    cursor: pointer;
}

.sidebar {
    background: rgba(10, 80, 161, 0.05);
    padding: 20px 20px 30px;
}

.sidebar h4 {
    color: #142942;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
}

.sidebar .list-block {
    margin: 15px 0px;
}

.sidebar .green-btn {
    width: 100%;
}

.list-block {
    margin: 15px 0px;
}

.list-block ul li {
    list-style: none;
    font-size: 20px;
    font-weight: 600;
    font-family: "Source Sans Pro", sans-serif;
    color: #0A50A1;
}

.list-block.meeting-list ul li {
    color: #E61800;
}

.common-header-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 15px;
    position: relative;
}

.common-header-row-new {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content:flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 15px;
    position: relative;
}
.common-header-row-new h4 {
    color: #142942;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
}
.common-header-row h4 {
    color: #142942;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
}

.common-header-row.common-header-with-large-text {
    margin-bottom: 45px;
    justify-content: space-between;
}

.common-header-row.common-header-with-large-text h4 {
    color: #142942;
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 0;
}

.common-header-row.common-header-with-large-text .green-btn,
.common-header-row.common-header-with-large-text .add-task-btn {
    margin-left: 15px;
}

.task-listing label {
    color: #142942;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding-left: 0;
}

.task-listing label a {
    text-decoration: none;
    color: #142942;
}

.task-listing .mdc-form-field {
    position: relative;
    width: 100%;
    border-bottom: 1px solid rgba(10, 80, 161, 0.1);
    padding: 12px 0px;
    display: block;
}

.task-listing .mdc-form-field .mdc-checkbox {
    position: absolute;
    right: 0;
    top: 4px;
}

.task-listing .mdc-form-field:last-child {
    border-bottom: none;
}

.task-listing .date-field {
    display: block;
    color: #557191;
    font-weight: 500;
}

.task-listing.task-list-with-media .mdc-form-field {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.task-listing.task-list-with-media .mdc-form-field .user-icon {
    margin-right: 15px;
}

.task-listing.task-list-with-media .mdc-form-field .user-icon img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    -o-object-fit: cover;
    object-fit: cover;
}

.task-listing.task-list-with-media .mdc-form-field .line-icon {
    position: absolute;
    right: 0;
    top: 50%;
}

.time-date-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.time-date-row .time-block text,
.time-date-row .date-block text,
.time-date-row .location-block text {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #142942;
    font-family: "Source Sans Pro", sans-serif;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 7px;
}

.time-date-row .time-block .material-icons,
.time-date-row .date-block .material-icons,
.time-date-row .location-block .material-icons {
    margin-right: 10px;
    color: #0A50A1;
}

.time-date-row .time-block p,
.time-date-row .date-block p,
.time-date-row .location-block p {
    color: #557191;
    font-size: 20px;
    font-family: "Source Sans Pro", sans-serif;
}

.time-date-row .location-block {
    width: 100%;
    margin-top: 20px;
}

.time-date-row .time-block {
    margin-right: 35px;
}

.mdc-button.add-task-btn {
    border-radius: 0;
    background: rgba(10, 80, 161, 0.1);
    border: 1px solid #0A50A1;
    color: #0A50A1;
}


/*pages*/

.user-pages-wrap {
    padding: 40px 0px;
}

.user-pages-wrap .media-block {
    text-align: right;
}

.educator-list-page-header .common-header-row .search-educators {
    margin-bottom: 0;
}

.educator-list-page-header .common-header-row {
    justify-content: space-between;
}

.educator-list-page-header .block-right {
    align-items: center;
}

.dashboard-wrap .educator-list-page-header .block-right .search-educators {
    margin-bottom: 0;
}

@media (max-width: 767px) {
    .user-pages-wrap .media-block img {
        width: 100%;
        margin-top: 50px;
    }
}

.login-form .common-heaidng48 {
    margin-bottom: 15px;
}

.login-form .small-title {
    margin-bottom: 30px;
}

.login-form form .mdc-text-field {
    width: 100%;
    margin-bottom: 15px;
}

.login-form form .mdc-button {
    width: 100%;
    margin-top: 20px;
}

.login-form .forget-pwd-link {
    display: inline-block;
    margin-top: 75px;
}

.user-bloxes-list {
    overflow: hidden;
    margin: 35px 0px;
}

.user-bloxes-list li {
    width: calc(50% - 20px);
    height: 187px;
    background: rgba(10, 80, 161, 0.05);
    list-style: none;
    float: left;
    border-radius: 4px;
    margin: 0 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.user-bloxes-list li .media-row img {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    margin-bottom: 7px;
}

.user-bloxes-list li .designaiton {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #142942;
    display: block;
    margin-bottom: 5px;
}

.user-bloxes-list li p {
    color: #557191;
    font-size: 14px;
}

.user-bloxes-list li.active {
    background: #0A50A1;
}

.user-bloxes-list li.active .designaiton {
    color: #fff;
}

.user-bloxes-list li.active p {
    color: #fff;
    opacity: 0.8;
}

.user-types-wrap .small-title {
    margin: 15px 0px 40px;
}

.sign-up-form {
    padding-bottom: 40px;
}

.sign-up-form .mdc-text-field {
    margin: 15px 0px;
}

.sign-up-form .green-btn {
    margin-top: 25px;
}

@media (min-width: 600px) {
    .btn-large {
        width: 476px;
    }
}

.operational-days-block {
    text-align: left;
    margin: 10px 0px 15px;
}

.operational-days-block label {
    margin-bottom: 7px;
    display: block;
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #0A50A1;
}

.operational-days-listing {
    display: -webkit-box;
    display: -ms-flexbox;
    /* display: flex; */
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media (max-width: 767px) {
    .operational-days-listing {
        display: block;
    }
}

.operational-days-listing li {
    border: 1px solid rgba(85, 113, 144, 0.7);
    height: 36px;
    padding: 0px 19px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 4px;
    margin: 0 5px;
    font-size: 14px;
    font-weight: 500;
    color: #557190;
    text-transform: uppercase;
    cursor: pointer;
}

@media (max-width: 767px) {
    .operational-days-listing li {
        margin: 5px;
    }
}

.operational-days-listing li {
    margin: 10px;
    padding: 23px 25px;
}

.operational-days-listing li:last-child {
    margin: 10px;
}

.operational-days-listing li.active {
    background: #33A02C;
    color: #fff;
    border-color: #33A02C;
}

@media (min-width: 768px) {
    .course-detail-page form .custom-row {
        margin-bottom: 30px;
    }
}

.notice-listing-block {
    padding-top: 20px;
}

.notice-listing-block .task-list-with-media table tbody tr td.MuiTableCell-root a.educatorProfile {
    background-color: #33a02c;
    border-radius: 0;
    padding: 10px 10px 10px 10px;
    color: #fff;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.notice-listing-block .task-list-with-media table tbody tr td.MuiTableCell-root a.educatorProfile:hover {
    -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.notice-listing-block .MuiPaper-root .MuiAccordionSummary-content h4.accordion-title {
    width: 100%;
}

.notice-listing-block .MuiPaper-root .MuiAccordionSummary-content h4.accordion-title a.view-all-link {
    margin-left: 5px;
    float: right;
}

a.edit-green-btn {
    background: #33a02c;
    color: #fff;
    padding: 6px 15px 4px 15px;
    text-decoration: none;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

a.edit-green-btn:hover {
    -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

button.delete-red-btn {
    background: red;
    color: #fff;
    padding: 6px 15px 4px 15px;
    text-decoration: none;
    border: 0;
    font-size: 0.875rem;
    line-height: 17px;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

button.delete-red-btn:hover {
    -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

body .loader-wrap .MuiDialog-paperWidthSm {
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: transparent;
    position: relative;
}

.MuiButton-containedPrimary {
    height: 37px;
    line-height: 41px;
}



.dashboard-wrap .MuiTableContainer-root.educators-leaves-listing table tbody tr:last-child td {
    border: none;
}

.dashboard-wrap .sidebar {
    margin-bottom: 15px;
}

.react-responsive-modal-modal button.react-responsive-modal-closeButton {
    right: 10px;
    top: 10px;
}

.react-responsive-modal-modal button.react-responsive-modal-closeButton svg {
    width: 22px;
    height: 22px;
}

.react-responsive-modal-container form .calendar-wrap {
    margin-bottom: 10px;
}

.react-responsive-modal-container form button.mdc-button.mdc-button--raised.mt-40 {
    margin-top: 10px;
}

form.sign-up-form .textfield-with-small-desciption .css-yk16xz-control {
    height: 56px;
}

.task-listing.task-list-with-media table tbody tr td:last-child {
    padding: 0;
    width: 120px;
}

.search-educators {
    height: 38px;
}

.search-educators span.material-icons {
    top: 16px;
}

.calender-block .calender-media img {
    max-width: 100%;
}

.content-block-right.link-detail-page .meeting-notes .detail-media-block {
    display: grid;
    grid-template-columns: repeat(4, 70px);
    gap: 5px;
}

.content-block-right.link-detail-page .meeting-notes .detail-media-block img {
    height: 70px;
}

/*14-10-2021 css started*/
.content-block-right.link-detail-page {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
}

.content-block-right.link-detail-page .meeting-notes {
    margin-bottom: 0 !important;
    margin-top: 0;
}

.notice-listing-block .common-header-row .block-right .search-educators input {
    padding-right: 80px;
}

.notice-listing-block .common-header-row .block-right .search-educators span.material-icons {
    right: 8px;
}

.notice-listing-block .common-header-row .block-right {
    display: inline-flex;
    gap: 15px;
}

.dashboard-wrap .content-block-right .common-header-row {
    margin-top: 20px;
    align-items: center;
    margin-bottom: 20px;
}

.dashboard-wrap .content-block-right .common-header-row h4 {

    margin-top: 0 !important;
    padding-top: 15px;

}

.select-boxes .MuiInputBase-root {
    margin-left: 15px;
}

.dashboard-wrap .content-block-right .search-educators {
    margin-bottom: 20px;
}

.ml-10 {
    margin-left: 10px;
}

.upload-doc-block>.row {
    align-items: flex-start;
}

.uploaded-block .upload-content {
    float: left;
    margin-right: 5px;
    display: inline-flex;
    align-items: flex-start;
    gap: 5px;
}

.uploaded-block .upload-content .media {
    box-shadow: 0 0 5px #ccc;
    width: 48px;
    height: 48px;
}

.uploaded-block .upload-content .media img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.uploaded-block .upload-content button {
    vertical-align: top;
}

.uploaded-block .upload-content button span {
    display: block;
    font-size: 15px;
}

.common-header-row {
    padding-top: 8px;
    margin-top: 0 !important;
    gap: 15px;
}

.notice-listing-block.link-page .notice-info {
    margin-left: 0;
    width: 100%;
}

.mt-0 {
    margin-top: 0 !important;
}

.mb-20 {
    margin-bottom: 20px;
}

.pt-0 {
    padding-top: 0 !important;
}

.notice-listing-block.link-page {
    padding-top: 0;
}

.user-pages-accordion-wrap.view-profile-page .accordion-content {
    margin-bottom: 20px;
}

.operational-days-listing .flex {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
}

.operational-days-listing .flex li {
    margin: 0;
    padding: 27px;
}

.operational-days-listing .flex .MuiFormControl-root {
    margin: 0 !important;
    width: 250px;
}

.task-listing-block.m-n-s-block .common-header-row {
    justify-content: space-between;
}

.sorting-block {
    display: inline-flex;
    gap: 25px;
    flex-direction: row-reverse;
    align-items: center;
}

.task-listing-block.tasks-page-block .common-header-row {
    justify-content: space-between;
}

.sc-dkrFOg .pagination {
    float: left;
}

.common-header-row.library-page-block {
    margin-bottom: 40px !important;
}

.common-header-row.library-page-block .btn-right {
    margin-top: 0;
}

.row.notice_list .card-block-without-border .card-header .row {
    margin: 0;
}

.row.notice_list .card-block-without-border {
    border-bottom: 1px solid rgba(10, 80, 161, 0.1);
    margin-bottom: 10px;
}

.row.notice_list .card-block-without-border p {
    margin-bottom: 0;
}

.user-pages-wrap.login-page .media-block img {
    width: 70%;
}

.dashboard-wrap.educator-dashboard-page .educators-leaves-listing {
    margin-top: 0;
}

.sign-up-form button span {
    display: block;
}

button.close-btn {


    margin: 0px 10px;

}

/* .ms-style{
    
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-left: 75px;
} */

.add-more-resource-cross button.close-btn,
.add-more-url-cross button.close-btn {
    /* position: absolute; */
    top: -15px;
    right: 5px;
}

.add-more-url-cross {
    margin-bottom: 20px;
}

button.close-btn.resource-button.cross-btn-create-visit {
    align-self: baseline;
    margin-top: 21px;
    margin-left: 129px;
}

.user-types-wrap.course-detail-page form.sign-up-form .col-lg-6+button {
    height: fit-content;
}

@media(max-width: 475px) {
    .content-block-right {
        grid-template-columns: repeat(1, 1fr);
    }

    .notice-listing-block .common-header-row .block-right .search-educators span.material-icons {
        right: 2px;
    }
}


li.little-left {
    margin-left: 29px;
}

.center-text-with-no-data {
    position: absolute;
    left: 31%;
    margin-top: 15px;
    font-size: 18px;
    font-weight: bolder;
}



.breadcrun-of-folder-path {
    font-size: 22px;
    font-weight: bold;
}

span.highlight-assigned-to {
    font-weight: 500;
    font-size: 15px;
}

span.material-icons.align-arrow-end {
    float: right;
    font-size: 39px;
}

span.material-icons.align-icon-right {
    font-size: 33px;
}

.custom-dashed-button.mark-button-center {
    width: 50%;
    margin: 0 auto;
}

textarea#completionTimeframe {
    padding-top: 10px;
}

button.mdc-button.mdc-button--raised.green-btn.ml-5 {
    margin-left: 44px;
}


.reminder-field-style {
    font-size: 13px;
    line-height: 1px;
    color: #1255A3;
    background: none;
    border: none;
    z-index: 50;
    font-weight: 400;
}

label.mdc-text-field.mdc-text-field--outlined.long-text-area-field {
    height: 121px;
}

.row.formatting-in-hours {
    margin-bottom: 13px;
}

p.meeting-notes-pre-aligned {
    white-space: pre-line;
}

p.adding-heading-css {
    font-size: 19px;
    margin-left: 11px;
    font-weight: 500;
}

p.adding-heading-right-css {
    font-size: 19px;
    margin-right: 25px;
    font-weight: 500;
}

.col-md-7.logo-image-css {
    position: absolute;
    left: 15%;
    top: 35%;
}

.home-logo-design-css {
    height: 30%;
    width: 79%;
}



.dropdown1 {
    position: absolute;
    margin-top: 5px;
    padding: 5px 5px 0 0;
    right: 0;
    top: 28px;
    z-index: 99;
    background: #FFFFFF;
    -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 20px;
}

.dropdown1 li {
    list-style: none;
    color: #142942;
    font-size: 16px;
    margin: 10px 0px;
}

.dropdown1 li a {
    color: #142942;
    font-size: 16px;
    text-decoration: none;
}

.select-field .dropdown1 {
    left: 0;
    text-align: left;
    top: 66px;
}


.dashboard-wrap .MuiTableContainer-root.educators-leaves-listing {
    overflow: scroll;
    margin-top: 20px;
    background: rgba(10, 80, 161, 0.05);
    padding: 5px 20px 5px;
    height: 25rem;
}



.ceneterd-text {
    text-align: center;
}

.sidebar-locker {
    height: 50%;
    overflow: scroll;
    border-radius: 10px;
}

/* changes 13-12-2022 */
.c-box-parent .c-tab:not(:last-child) {
    border-bottom: 1px solid #000;
    margin-bottom: 10px;
}

.c-box-parent .c-content {
    display: flex;
    justify-content: space-between;
}

.c-box-parent .c-content p {
    font-size: 24px;
    line-height: 36px;
}

.c-box-parent .c-content h2 {
    font-size: 18px;
    line-height: 24px;
    color: #000;
}

.pb-25 {
    padding-bottom: 25px;
}

.c-box-parent .c-content ul li {
    list-style: none;
}

.c-box-parent .c-content ul .list-content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.list-content-title {
    font-weight: 500;
    color: #000;
}

.reminder-dashboard-popup {
    font-weight: 500;
    color: black;
    font-size: 33px;
}

.reminder-popup-subheading {
    font-weight: 500;
    color: #104786;
    ;
    font-size: 19px;
}


.sc-dkrFOg {
    display: flex;
    flex-direction: column;
}

.sign_clear_button {
    background-color: #6200ee;
    border: none;
    border-radius: 10px;
    color: white;
    padding: 10px;
    cursor: pointer;
}

.clear_button_block {
    display: flex;
    margin: 10px;
}

.dashboard-wrap .sidebar.sidebar-locker .task-listing-block {
    max-height: 900px;
    height: 100%;
}

.dashboard-wrap .sidebar.sidebar-locker {
    max-height: 900px;
    height: 100%;
    overflow: auto;
    margin-bottom: 50px;
}

.profe-logs .block-right {
    width: 100%;
}

.dashboard-wrap .profe-logs .block-right .risk-assi-btns {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.dashboard-wrap .profe-logs .block-right .risk-assi-btns {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.header-bar .right-block .notification-block .common-listing-block-notifications ul.mdc-list li.mdc-list-item span.material-icons {
    width: 24px;
    float: left;
    margin: 0;
    position: relative;
    top: 2px;
}

.header-bar .right-block .notification-block .common-listing-block-notifications ul.mdc-list li.mdc-list-item {
    display: block;
}

.header-bar .right-block .notification-block .common-listing-block-notifications ul.mdc-list li.mdc-list-item span.mdc-list-item__text {
    float: left;
    width: calc(100% - 24px);
    padding-left: 5px;
}

.header-bar .right-block .notification-block .common-listing-block-notifications ul.mdc-list li.mdc-list-item span.mdc-list-item__secondary-text {
    display: block;
    overflow: visible;
    white-space: break-spaces;
}

/* .MuiPopover-root .MuiPaper-root.MuiMenu-paper {
    max-height: calc(100% - 240px);
} */
@media(max-width: 1199px) {
    .common-header-row.risk--assignment .risk-assi-btns button {
        margin-left: 15px;
        height: auto;
        padding: 12px;
        letter-spacing: 0;
    }
}

@media(max-width: 767px) {
    .full-width-calender .Calendar .Calendar__day {
        width: calc(14.28571% - 10px);
        margin: 6px 5px;
    }

    .full-width-calender .Calendar .Calendar__day {
        min-height: 45px;
    }

    .full-width-calender .Calendar__weekRow {
        justify-content: flex-start;
    }

    .common-header-row.risk--assignment .risk-assi-btns {
        flex-wrap: wrap;
        gap: 15px;
    }

    .common-header-row.risk--assignment .risk-assi-btns button {
        width: 100%;
    }
}

@media(max-width: 575px) {
    .header-bar .right-block .notification-block {
        position: unset;
    }

    .header-bar .right-block .notification-block .common-listing-block-notifications {
        width: 100%;
    }

    .common-header-row.risk--assignment .risk-assi-btns button {
        width: 100%;
        height: auto;
        padding: 10px;
        letter-spacing: 0;
        font-size: 13px;
    }

    .common-listing-block-notifications ul li {
        padding-left: 0;
    }
}

@media(max-width: 475px) {
    .full-width-calender .Calendar .Calendar__day {
        min-height: 35px;
    }
}

@media(max-width: 400px) {
    .full-width-calender .Calendar .Calendar__day {
        min-height: 25px;
    }
}

.del {
    margin-left: 12px;
}

.custom-select {
    width: 540px;
    padding: 15px;
    position: relative;
    border-radius: 4px;
}
.custom-select-small {
    width: 255px;
    padding: 16px;
    position: relative;
    border-radius: 4px;
}

/* Set the desired minimum margin for the page content print */
@media screen {
    .print-only-heading {
        display: none;
    }
}

.centered-heading {
    text-align: center;
}
 
.ms-action-buttons {
    display: flex !important;
}

.form-list-ms td {
    border: 0 !important;
}

.form-list-ms {
    border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
}