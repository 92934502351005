.visit_lists .material-icons {
    cursor: pointer;
}

.task-listing span.material-icons.line-icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    cursor: pointer;
}
.loaderBox {
    display: flex;
   justify-content: center;
    align-items: center;
    padding: 0px;
    margin-top: 100px;
    width: 100%;
}