.big-folders-list.folder-open-list li figure {
    height: 135px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.big-folders-list.folder-open-list li img {
    width: auto;
    max-width: 100%;
}

.big-folders-list.folder-open-list li {
    margin-bottom: 30px;
}
.pagination{
    width: 100%;
}