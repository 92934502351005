.custom-task-status-wrap {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
}

.common-header-row h4 .green-btn {
    margin-left: 15px;
}

.editTask {
    color: black;
    text-decoration: none;
}

.task-listing-block .material-icons {
    cursor: pointer;
}