.user-pages-accordion-wrap .accordion-container .accordion-title::after {
  right: 20px;
  left: auto;
}

row > .col-xs-3 {
  display:flex;
  flex: 0 0 25%;
  max-width: 25%;
  overflow: hidden;
}

.flex-nowrap {
  -webkit-flex-wrap: nowrap!important;
  -ms-flex-wrap: nowrap!important;
  flex-wrap: nowrap!important;
}
.flex-row {
  display:flex;
  -webkit-box-orient: horizontal!important;
  -webkit-box-direction: normal!important;
  -webkit-flex-direction: row!important;
  -ms-flex-direction: row!important;
  flex-direction: row!important;
}

.task-listing .row.flex-row {
  overflow-x: scroll;
  overflow-y: hidden;
}

.title{
  font-weight: bold;
}

.arrowDown{
  margin-top:  15px;
  float: right;
}

.btnSave{
  float: right;
}

.d-flex {
  display: flex;
  align-items: center;
  column-gap: 10px;
}