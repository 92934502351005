.task-listing.scroll-element {
    margin-top: 15px;
}
.LQiaC {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0px;
    margin: 10px;
    width: 100%;
}
.buypnQ:first-of-type  {
    border-left: 1px solid rgb(193, 193, 193);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.buypnQ {
    list-style-type: none;
    padding: 10px;
    background: rgb(255, 255, 255);
    border-right: 1px solid rgb(193, 193, 193);
    border-top: 1px solid rgb(193, 193, 193);
    border-bottom: 1px solid rgb(193, 193, 193);
    color: rgb(68, 68, 68);
    cursor: pointer;
    display: flex;
}
.buypnQ.active {
    background: rgb(207, 207, 207);
}
