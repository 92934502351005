.attach-file {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.time-block .react-time-picker {
    position: absolute;
    left: 0;
    top: 71px;
}

.time-block {
    position: relative;
}

.short-description {
    position: relative;
    top: -15px;
    width: 100%;
    display: block;
}