.editNotice {
    color: black;
    text-decoration: none;
}

.row.notice_list .material-icons {
    cursor: pointer;
}

.notice_list .card-header {
    padding-left: 25px;
    padding-right: 25px;
}
.notice_list .card-header .row {
    justify-content: space-between;
}
.notice_list .card-header .row p time {
    margin-right: 10px;
}
.notice_list .card-header .row a {
    color: #0951a1;
    font-weight: 600;
}
.vl {
    border-left: 6px solid rgb(0, 0, 0);
    height: 500px;
  }