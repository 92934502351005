.signature_field {
    max-width: 200px;
    height: auto
}

.short-description {
    position: relative;
    top: -15px;
    width: 100%;
    display: block;
}