.purpleDay {
    background-color: purple;
    color: white;
}

.greenDay {
    background-color: green;
    color: white;
}

.yellowDay {
    background-color: yellow;
    color: black;
}

.react-responsive-modal-modal .calender-popup {
    box-shadow: none;
}

.react-responsive-modal-modal .calender-popup h1.common-heading36 {
    text-align: left;
}

.orangeDay {
    background-color: orange;
    color: white;
}


/* Calendar Popup */

.calender-popup .tabs #tabs-nav {
    display: flex;
    border-bottom: 1px solid #142942;
}

.calender-popup.calender-popup-with-tabs .green-btn {
    width: auto;
    margin: 0;
}

.calender-popup-with-tabs .tab-content ul li {
    display: flex;
    justify-content: space-between;
    margin: 10px 0px;
    align-items: center;
}

.calender-popup-with-tabs .tab-content ul li h2 {
    margin: 0;
    font-size: 18px;
    max-width: 470px;
    line-height: 24px;
}

.calender-popup-with-tabs {
    height: 350px;
    padding: 60px 45px 35px;
}

.calender-popup-with-tabs .tab-content {
    margin-top: 30px;
    height: 179px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 10px;
}

.calender-popup-with-tabs span.material-icons.close-icon {
    right: 22px;
    top: 22px;
}

.calender-popup-with-tabs .tab-content .reminders-tab-info ul li h2 {
    max-width: 100%;
}

body .react-responsive-modal-modal {
    overflow: auto;
    height: 530px;
}