.user-block .user-media-row .media img {
    height: 98px;
    width: 98px;
    border-radius: 50%;
}

.user-block .user-media-row .media .small-thumb {
    width: auto;
    height: auto;
}

.user-block .user-media img {
    height: 48px;
}

.header-bar .right-block .notification-block .common-listing-block,
.header-bar .right-block .user-block .common-listing-block {
    z-index: 99;
}

.header-bar .right-block .common-listing-block-notifications {
    right: 0;
    top: 62px;
}

.header-bar .right-block .notification-block .common-listing-block-notifications {
    max-height: 425px;
    overflow-x: hidden;
    overflow-y: auto;
}

.common-listing-block-notifications {
    background: #F3F6FA;
    -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    width: 444px;
    position: absolute;
    z-index: 99;
    padding: 25px;
}

.common-listing-block-notifications .mdc-list-item__secondary-text::before {
    height: 0;
    display: none;
}

.header-bar .right-block .user-block .common-listing-block-notifications {
    display: none;
}

.common-listing-block-notifications ul li {
    margin: 10px 0px;
}

.common-listing-block-notifications ul li span.mdc-list-item__text {
    border-bottom: 1px solid rgba(10, 80, 161, 0.1);
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 2px;
    position: relative;
}

.common-listing-block-notifications ul li span.mdc-list-item__text span.material-icons.arrow-forward {
    position: absolute;
    right: 0;
    top: 0px;
    color: #0A50A1;
}

.common-listing-block-notifications ul li span.mdc-list-item__primary-text {
    color: #142942;
}

.common-listing-block-notifications ul li span.material-icons {
    margin: 8px 25px 0 0px;
}

.common-listing-block-notifications ul li:last-child span.mdc-list-item__text {
    border-bottom: none;
}

.common-listing-block-notifications a {
    text-decoration: none;
}

.common-listing-block .user-media-row .media input[type="file"] {
    position: absolute;
    top: 70px;
    opacity: 0;
    left: -120px;
    z-index: 9;
}