.dropdown button {
    background: #33A02C;
    color: #fff;
    border: navajowhite;
    padding: 5px 10px;
    width: 100%;
    cursor: pointer;
    text-transform: uppercase;
}

ul.dropdown {
    padding: 3px 20px;
    right: 9px;
    top: 52px;
}

.notice-list .notice-info span.material-icons {
    cursor: pointer;
}

.editLink {
    color: black;
    text-decoration: none;
}