#add-video {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

#add-file {
    opacity: 0;
    position: absolute;
    z-index: -1
}

#add-digital-signature {
    opacity: 0;
    position: absolute;
    z-index: -1
}

.search-select-field {
    margin-top: 15px;
}

.search-select-field>span+div {
    height: 56px;
}

.calendar-wrap .react-calendar {
    position: absolute;
    z-index: 9;
    top: 72px;
    left: 0;
}

.calendar-wrap {
    position: relative;
}

.time-block .react-time-picker {
    position: absolute;
    left: 0;
    top: 71px;
}

.time-block {
    position: relative;
}

.signature_title {
    text-align: left;
    margin-top: 35px;
    font-size: 20px;
    color: #142942;
}

.short-description {
    position: relative;
    top: -15px;
    width: 100%;
    display: block;
}

#attach-file{
    display: none;
}

.addIconStyle{
    margin-top: 1.3em;
    color: #6100ee;
}