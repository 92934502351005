.common-heading36 {
    text-align: center;
}

.calender-popup.booking-popup {
    position: fixed;
    width: 600px;
    z-index: 2;
    background: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.popup-layer:after {
    content: '';
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: 1;
}