#add-link {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

#add-video {
    opacity: 0;
    position: absolute;
    z-index: -1
}

#add-digital-signature {
    opacity: 0;
    position: absolute;
    z-index: -1
}

.search-select-field {
    margin-top: 15px;
}

.search-select-field>span+div {
    height: 56px;
}

.time-block .react-time-picker {
    position: absolute;
    left: 0;
    top: 71px;
}

.time-block {
    position: relative;
}

.short-description {
    position: relative;
    top: -15px;
    width: 100%;
    display: block;
}
button.resource-button {
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    margin-left: 75px;
    
}